@font-face {
  font-family: 'Roboto Regular';
  src: url('./fonts/Roboto-Regular.eot');
  src: url('./fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Regular.svg#Roboto-Regular') format('svg'),
    url('./fonts/Roboto-Regular.ttf') format('truetype'),
    url('./fonts/Roboto-Regular.woff') format('woff'),
    url('./fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('./fonts/Roboto-Bold.eot');
  src: url('./fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Bold.svg#Roboto-Bold') format('svg'),
    url('./fonts/Roboto-Bold.ttf') format('truetype'),
    url('./fonts/Roboto-Bold.woff') format('woff'),
    url('./fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Black';
  src: url('./fonts/Roboto-Black.eot');
  src: url('./fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Black.svg#Roboto-Bold') format('svg'),
    url('./fonts/Roboto-Black.ttf') format('truetype'),
    url('./fonts/Roboto-Black.woff') format('woff'),
    url('./fonts/Roboto-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('./fonts/Roboto-Medium.eot');
  src: url('./fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Medium.svg#Roboto-Medium') format('svg'),
    url('./fonts/Roboto-Medium.ttf') format('truetype'),
    url('./fonts/Roboto-Medium.woff') format('woff'),
    url('./fonts/Roboto-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('./fonts/Roboto-Light.eot');
  src: url('./fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Roboto-Light.svg#Roboto-Light') format('svg'),
    url('./fonts/Roboto-Light.ttf') format('truetype'),
    url('./fonts/Roboto-Light.woff') format('woff'),
    url('./fonts/Roboto-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

#root {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: 'Roboto Bold', 'Lucida Sans', 'Lucida Sans Regular',
    'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif,
    'Roboto Bold';
  color: white;
  background-color: #242424;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
